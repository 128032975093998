<template>
  <v-row xs="12" v-if="item.mediaType && (itemToDisplay || metricToDisplay)" no-gutters class="px-4">

  <v-card rounded class="pa-5 px-2 mb-1 flex" min-height="160" color="grey lighten-4" flat>
    
    <v-row class="fill-height" no-gutters align="center" justify="center" >
      <metric-thumbnail :showChart="false" :specificRange="true" v-if="metricToDisplay" :metricToDisplay="metricToDisplay"/>

    <v-card v-if="itemToDisplay" flat outlined style="border-radius: 6px; overflow:hidden; min-width: 300px;">

<v-card v-if="itemToDisplay.media === 'condition' || itemToDisplay.media === 'medication'" tile class="pa-4" flat>
      <div v-if="itemToDisplay.media === 'condition'" >
      
        <div class="font-weight-bold text-body-1" >
           {{itemToDisplay.code}}
        </div>
        <div class="font-weight-light text-body-2" >
           {{itemToDisplay.name}}
        </div>
    </div>

    <div v-if="itemToDisplay.media === 'medication'">

        <div class="font-weight-bold text-body-1">
           {{itemToDisplay.drug}}
        </div>
        <div class="font-weight-light text-body-2" >
           {{itemToDisplay.strength}}
        </div>
    </div>
</v-card>

    </v-card>
    </v-row>
  </v-card>
  </v-row>
</template>

<script>
import moment from "moment-timezone";
import MetricThumbnail from './MetricThumbnail.vue';
import consts from '@/consts'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'
import get from 'lodash/get'

export default {
    mixins: [HelperMixin],
    components: { MetricThumbnail },
props: {
    item: null
},
data() {
    return {
      metricToDisplay: null,
      itemToDisplay: null
    }
  },
watch: {

  item: {
    immediate: true,
    handler() {
      this.setUpMetricThumbnail()
    },
  }
},
methods: {
  ...mapActions('notes', ['getEventsForMetric']), 
  setUpMetricThumbnail() {

    let itemToUse = this.item.fullNote
    
    if (this.item.mediaType === 'metric') {
      let metricEventType = get(itemToUse, 'eventData.referenceData.data.eventType', null)

    if (metricEventType) {
      let metricInformation = {}
            let keys = Object.keys(consts.metricList[metricEventType])
            keys.forEach(key => {
              metricInformation[key] = consts.metricList[metricEventType][key]
            })
            this.metricToDisplay = metricInformation
            this.metricToDisplay.latestEvent = itemToUse.eventData.referenceData.data

    } else {
      // that means its a really old metric event.... just ignore for now.
    }
            
            
   
          }

          if (this.item.mediaType === 'medication') {
            // this means the media is not a metric
            let note = itemToUse
        let noteObject = {}
            
        if (note.eventData && note.eventData.referenceData && itemToUse.eventData.referenceData.data && itemToUse.eventData.referenceData.data.fullItem) {
          noteObject.mediaCardColor = 'grey lighten-5'
          noteObject.media = note.eventData.referenceData.type
          if (noteObject.media === 'medication' && note.eventData.referenceData.data.fullItem) {
            
            noteObject.drug = note.eventData.referenceData.data.fullItem.split("|")[0]
            noteObject.strength = note.eventData.referenceData.data.fullItem.split("|")[1]

          }
        }

        this.itemToDisplay = noteObject
          }
          
        if (this.item.mediaType === 'condition') {
          let note = itemToUse
let conditionInformation = get(note, 'eventData.referenceData.data.fullItem', null)

        let noteObject = {}

        noteObject.mediaCardColor = 'grey lighten-5'
          noteObject.media = note.eventData.referenceData.type
        
            // there might be old data in here complicating things
            if (get(conditionInformation, 'compound', null)) {
              noteObject.code = note.eventData.referenceData.data.fullItem.compound.split("|")[0]
            noteObject.name = note.eventData.referenceData.data.fullItem.compound.split("|")[1]
            noteObject.mediaSingleLine = note.eventData.referenceData.data.fullItem.compound.split("|")[1]
            } else if (conditionInformation && conditionInformation.split('|')[0]) {
              noteObject.code = note.eventData.referenceData.data.fullItem.split("|")[0]
            noteObject.name = note.eventData.referenceData.data.fullItem.split("|")[1]
            noteObject.mediaSingleLine = note.eventData.referenceData.data.fullItem.split("|")[1]
            }

            this.itemToDisplay = noteObject
          }
          
        
        
          
  },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);
      
      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 3) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
},
computed: {
    ...mapState('auth', ['user', 'configuration', 'partner', 'appTheme', 'orgUsers', 'demoFeatures']),
},
mounted() {
  
  this.setUpMetricThumbnail()
}
}
</script>

<style>

</style>