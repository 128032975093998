<template>
<v-card color="transparent" flat class="flex">
<v-card v-if="!automatedChartNote" flat outlined rounded="lg" class="flex" >

  <v-fab-transition>
                  <v-btn
                    color="primary"
                    fab
                    dark
                    x-small
                    style="position: absolute; top: -15px; left: -10px;"
                    v-show="item.unread"
                    @click="dismissUnread()"
                  >
                    <v-icon small>mdi-at</v-icon>
                  </v-btn>
                </v-fab-transition>

  <v-row justify="space-between" no-gutters>
    <v-list-item >
      <v-list-item-avatar size="36" class="mr-3" color="primary">
        <v-icon size="20" dark>mdi-account</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
   <v-list-item-title class="font-weight-medium text-subtitle-1">
     {{ item.author }}
   </v-list-item-title>
   <v-list-item-subtitle class="font-weight-medium">
     {{relativeTime(item.timestamp)}}
   </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-row v-if="item.mentionText" no-gutters>
    <v-chip color="grey lighten-4" >
      <v-icon left class="" small color="primary">mdi-at</v-icon>
    <span >{{item.mentionText}}</span>
    </v-chip>
    <v-chip class="px-1" color="transparent">
    <span style="opacity:0.5">{{item.additionalMentions}}</span>
    </v-chip>
    </v-row>
      </v-list-item-action>
    </v-list-item>

    

  </v-row>
  <!-- the text or media should start immediately after this. no top padding. this needs to feel super lightweight and data dense-->
  <chart-note-media class="mb-2" :item="item"/>
  <p :id="item.timestamp"  class="font-weight-regular text-body-1 px-4 pt-0 pb-0 " >

{{item.text}}
  </p>

  
  <v-divider v-if="item.replies.length > 0" class="mb-5"/>
  <!-- <v-row  v-if="item.replies.length > 0" no-gutters class="mx-4 text-subtitle-2" justify="end">
    {{item.replies.length}} comment<span v-if="item.replies.length > 1">s</span>
  </v-row> -->
  <v-row v-for="(reply, index) in item.replies" :key="index" class="mx-4" no-gutters>
    <v-card style="opacity:0.9" flat class="mb-1 pb-1 pt-0">
      <v-row align="start" no-gutters>
        <v-avatar class="mr-3" size="28" color="grey lighten-2">
        <v-icon size="16">mdi-account</v-icon>
        </v-avatar>
        <v-col>
        <v-row align="center" no-gutters class="font-weight-medium text-subtitle-1">
        {{reply.author}}<span class="font-weight-regular text-subtitle-2 ml-1 text--secondary"> ∙ {{relativeTime(reply.timestamp)}}</span>
        </v-row>
        <v-card-text class="font-weight-regular text-body-1 px-0 pt-0 pb-0">
        {{reply.text}}
      </v-card-text>
        </v-col>
      </v-row>
      
    </v-card>
  </v-row>
  <v-row class="mx-4 mb-4 mt-2" no-gutters>
  <v-textarea :loading="savingResponse" v-model="newComment" @keydown.enter.stop.prevent="saveResponse()" hide-details placeholder="Write a comment"  solo flat background-color="grey lighten-4" row-height="16" rows="1" auto-grow>
  </v-textarea>
  </v-row>
</v-card>

<v-row class="px-2" align="center" v-if="automatedChartNote" no-gutters>
  <v-divider/><v-chip color="grey lighten-4" class="mx-3 text-caption ">
    <v-avatar left >
    <v-icon small :color="autoIconColor">{{automatedChartNoteIcon}}</v-icon></v-avatar>
    <span v-if="this.item.fullNote.eventData.referenceData.type === 'telehealth'" class="d-inline-block text-truncate"
        style="max-width: 350px;">{{automatedChartActionItemText}}</span> 
        <span v-else class="d-inline-block text-truncate"
        style="max-width: 350px;">{{ item.author }} {{automatedChartActionText}} {{automatedChartActionItemText}}</span> 
        
        
        <span class="text--secondary ml-1">{{relativeTime(item.timestamp)}} </span></v-chip> <v-divider/>
</v-row>
</v-card>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'
import moment from "moment-timezone";
import ChartNoteMedia from './ChartNoteMedia.vue';
import bus from '@/core/helpers/bus'

export default {
  components: { ChartNoteMedia },
    mixins: [HelperMixin],
props: {
    item: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      show: false,
      newComment: '',
      savingResponse: false,
      selectedMentions: []
    }
  },
  methods: {
    ...mapActions('notes', [
      'addChartNote',
      'registerLastChartNote',
      'removeChartNoteTagNotification'
    ]),
    dismissUnread() {
      console.log('this is dismissing')
      this.item.unread = false
      this.removeChartNoteTagNotification(this.item.id)
    },
    saveResponse() {

      if (this.newComment !== '') {
        this.savingResponse = true
        let noteMentions = []
        if (this.selectedMentions.length > 0) {
          this.selectedMentions.forEach(mention => {
            let userTag = {name: mention.displayName, userID: mention._id}
            noteMentions.push(userTag)
          });
          
        }
        let response = {
          patient: this.$route.params.id,
          partner: this.partner._id,
          sessionNote: this.newComment,
          referenceData: {thread: this.item.id, type: 'response'},
          noteMentions: [{name: this.item.fullNote.eventData.authorName, userID: this.item.fullNote.eventData.user}]
        }
        this.addChartNote(response).then(() => {

          response.eventData = {referenceData: {thread: this.item.id, type: 'response'}}
          response.text = response.sessionNote
          response.author = this.user.firstName + ' ' + this.user.lastName
          response.timestamp = new Date().getTime()

          this.item.replies.push(response)
          this.registerLastChartNote()
          
            bus.$emit('toast', { type: 'success', text: 'Response Saved' })
            this.newComment = ''
            this.savingResponse = false
            this.$emit('noteSaved')
          
        })
      }

    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);
      
      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 6) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
    msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor((s / (1000 * 60 * 60)))

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds
    }
  },
  computed: {
    ...mapState('patient', [
      'patients',
      'patient',
      'files',
      'isSearchingChart',
      'chartSearchResults',
      'chartSearchTerm',
      'isLoadingFiles',
      'displayedChartNotes'
    ]),
    ...mapState('provider', ['localProviderList']),
    ...mapState('auth', ['user', 'configuration', 'partner', 'appTheme', 'orgUsers', 'demoFeatures']),
    automatedChartNote() {
      // this is for system generated notes.
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.type && this.item.fullNote.eventData.type === 'auto') {
        return true
      }
      return false
    },
    automatedChartNoteIcon() {
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.referenceData && this.item.fullNote.eventData.referenceData.type === 'condition') {
        return 'mdi-bandage'
      }
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.referenceData && this.item.fullNote.eventData.referenceData.type === 'medication') {
        return 'mdi-pill'
      }
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.referenceData && this.item.fullNote.eventData.referenceData.type === 'telehealth') {
        return 'mdi-video'
      }
      return 'mdi-alert'
    },
    automatedChartActionText() {
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.action && this.item.fullNote.eventData.action === 'add') {
        return 'added'
      }
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.action && this.item.fullNote.eventData.action === 'remove') {
        return 'removed'
      }
      return null
    },
    autoIconColor() {
      if (this.automatedChartActionText === 'added') {
        return 'primary'
      }
      return 'red'
    },
    automatedChartActionItemText() {
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.referenceData && this.item.fullNote.eventData.referenceData.type === 'condition') {
        if (this.item.fullNote.eventData.referenceData.data.fullItem.description) {
          return this.item.fullNote.eventData.referenceData.data.fullItem.description
        } else {
          return this.item.fullNote.eventData.referenceData.data.name
        }
        
      }
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.referenceData && this.item.fullNote.eventData.referenceData.type === 'medication') {
        return this.item.fullNote.eventData.referenceData.data.name
      }
      if (this.item.fullNote && this.item.fullNote.eventData && this.item.fullNote.eventData.referenceData && this.item.fullNote.eventData.referenceData.type === 'telehealth') {
        return 'Telelhealth session with ' + this.item.author + ' lasting ' + this.msToTime(this.item.fullNote.eventData.referenceData.data.videoDuration)
      }
      return null
    },
    refPoint() {
      if (this.item.eventData) {
        return this.item.eventData.referenceData
      }
      return null
    },
    normalizedReferenceData() {
      if (this.refPoint) {
        if (this.refPoint.type === 'metric' && this.refPoint.data && this.refPoint.data) {
          // if this is
          return this.refPoint.data.dashboardAbbreviation + ' ' + this.refPoint.data.eventString + ' | ' + this.refPoint.data.readableDate
        }
        if (this.refPoint.type === 'medication' && this.refPoint.data && this.refPoint.data.displayName) {
          return this.refPoint.data.displayName
        }
        if (this.refPoint.type === 'condition' && this.refPoint.data && this.refPoint.data.name) {
          return this.refPoint.data.name
        }
        return 'refPoint title'
      }
      return null
    },
    refPointColor() {
      if (this.refPoint && this.refPoint.data && this.refPoint.data.alert) {
        return 'yellow'
      }
      return null
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
  },
  mounted() {
    this.show = true

    function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

if (this.item.unread) {
  // first check to see if it is in the ivew port now


if (isInViewport(document.getElementById(this.item.timestamp))) {
  let vm = this
  setTimeout( () => {
      vm.item.unread = false
      vm.removeChartNoteTagNotification(vm.item.id)
      }, 2500)
} else {
  let vm = this
    var observer = new IntersectionObserver(function(entries) {
	if(entries[0].isIntersecting === true && vm.item.unread) {

    // mark as read. 
    setTimeout( () => {
      vm.item.unread = false
      vm.removeChartNoteTagNotification(vm.item.id)
      }, 2500)
  }
    
}, { threshold: [1] });

observer.observe(document.getElementById(this.item.timestamp));
}
  
  

  }
  }
}
</script>

<style>

</style>