<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-card class="flex mb-4" outlined rounded="lg">
          <v-row justify="space-between" align="center" class="px-4" no-gutters>
            <v-avatar size="36" class="mr-3 my-3" color="transparent">
              <v-icon size="28">mdi-file-document-box-plus-outline</v-icon>
            </v-avatar>
            <v-col>
              <v-btn rounded height="38" depressed @click="createNewNote()" block large>
                <v-row class="text-none font-weight-regular text--secondary text-body-1" align="center" no-gutters>
                  Add a note to {{ patient.firstName }}'s chart...
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider v-if="savedNoteTemplates.length > 0" class="mx-4" />
          <v-row
            v-if="savedNoteTemplates.length > 0"
            align="center"
            style="height:40px; overflow: hidden"
            class="px-3 flex my-2"
            no-gutters
          >
            <v-btn
              height="40"
              v-for="(note, index) in savedNoteTemplates"
              :key="index"
              @click="createNewNote(note)"
              text
              ><v-icon color="primary" left>mdi-file-document-box-plus-outline</v-icon>{{ note.title }}</v-btn
            >
          </v-row>
        </v-card>
      </v-row>

      <v-row v-for="(item, index) in patientNotes" :key="index" no-gutters>
        <chart-note-card class="mb-4" :item="item" />
      </v-row>
      <v-row
        class="text--disabled text-subtitle-2 mt-10 pt-5"
        no-gutters
        justify="center"
        v-if="patientNotes && patientNotes.length === 0"
      >
        <div>
          <v-col>
            <v-icon color="grey lighten-3" size="120">mdi-file-document-box-outline</v-icon>
            <v-row justify="center"> </v-row>
          </v-col>
        </div>
      </v-row>
    </v-col>

    <portal to="patientFeedSecondRightPanel">
      <chart-notes-side-card class=" mr-4" :noteTypes="patientNoteTypes" :peopleTagged="activeUserList" />
    </portal>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import ChartNoteCard from '@/components/ChartNoteCard'
import bus from '@/core/helpers/bus'
import ChartNotesSideCard from './ChartNotesSideCard.vue'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      showNewPost: false,
      chartNotes: [],
      updatingNotes: false,
      notesDisplayed: 20,
      addingNotes: false,
      totalAvailable: 0,
      addNote: false,
    }
  },
  props: {
    inLine: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChartNoteCard,
    ChartNotesSideCard,
  },
  watch: {
    addNote(val) {
      if (val) {
        setTimeout(() => this.$refs.addNoteCard.focusOnNote(), 300)
      }
    },
    patient() {
      this.getPatientNotes()
    },
  },
  computed: {
    ...mapState('notes', [
      'patientNotes',
      'activeUsersForPatientNotes',
      'loadingPatientNotes',
      'patientNoteTypes',
      'savedNoteTemplates',
    ]),
    ...mapState('provider', ['localProviderList']),
    ...mapState('patient', ['patient']),
    ...mapState('auth', ['user', 'rpmPartner', 'appTheme', 'orgUsers']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    activeUserList() {
      let activeUsers = []

      if (this.orgUsers) {
        this.orgUsers.forEach(user => {
          if (this.activeUsersForPatientNotes.includes(user._id)) {
            activeUsers.push(user)
          }
        })
      }
      return activeUsers
    },
  },
  methods: {
    ...mapActions('auth', ['userAction']),
    ...mapActions('notes', ['getPatientNotes']),
    ...mapMutations('patient', ['setDisplayedChartNotes']),
    tagThisUserInNote(user) {
      bus.$emit('addNoteWithTag', user)
    },
    createNewNote(type) {
      if (type) {
        bus.$emit('addNoteWithType', type)
      } else {
        bus.$emit('addNote')
      }
    },
    getMoreNotes() {
      this.setDisplayedChartNotes(this.displayedChartNotes + 20)
      this.addingNotes = true
      this.getChartNotesForPatient({
        patient: this.$route.params.id,
        limit: this.displayedChartNotes,
      }).then(data => {
        this.addingNotes = false
        this.chartNotes = data.notes
        this.totalAvailable = data.total
      })
    },
  },
  mounted() {
    bus.$on('refreshNoteList', () => {
      this.getPatientNotes()
    })

    this.getPatientNotes(this.$route.params.id)
  },
}
</script>

<style></style>
