<template>
  <v-card class="flex pt-0" style="overflow-y:auto" tile color="transparent" flat>
    <v-divider class="mb-2" />
    <span class="text-body-2 font-weight-medium text--secondary">
      Chart Notes
    </span>
    <v-row no-gutters>
      <v-col>
        <v-card
          v-if="peopleTagged.length"
          class="text-subtitle-2 mb-2 py-0 pt-1"
          style="position: sticky; top: 0;"
          tile
          flat
          color="transparent"
        >
          <span class="text-subtitle-2">People Tagged</span>
          <v-list-item class="px-0 py-0" v-for="(user, index) in peopleTagged" :key="index" dense>
            <v-list-item-content>
              <v-list-item-title class=" text-subtitle-2 font-weight-regular text--secondary">
                {{ user.firstName }} {{ user.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-weight-regular text-subtitle-2 font-weight-regular ">
                {{ user.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <!-- <v-list-item-action>
        <v-btn small @click="tagThisUserInNote(user)" icon><v-icon>mdi-file-document-box-plus-outline</v-icon></v-btn>
      </v-list-item-action> -->
          </v-list-item>
        </v-card>

        <!-- <v-row v-for="(user, index) in peopleTagged" :key="index" class="pl-3 pr-3 pb-1" align="center" no-gutters justify="space-between" >
    
    <v-col>
    <v-row no-gutters class="text-caption font-weight-regular">{{user.firstName}} {{user.lastName}}</v-row>
    <span class="text-caption ">{{user.email}}</span>
    </v-col>
  </v-row> -->

        <v-card
          v-if="noteTypes.length"
          class="text-subtitle-1 mb-2 py-0 pt-1"
          style="position: sticky; top: 0;"
          tile
          flat
          color="transparent"
        >
          <span class="text-subtitle-2">Note Types</span>

          <v-row
            class="py-1"
            align="center"
            no-gutters
            justify="space-between"
            v-for="type in noteTypes"
            :key="type.title"
          >
            <span class="text-subtitle-2 font-weight-regular text--secondary">{{ type.title }}</span>
            <span class="text-subtitle-2 font-weight-regular">{{ type.count }}</span>
          </v-row>
        </v-card>

        <!-- <v-row v-for="(user, index) in peopleTagged" :key="index" class="pl-3 pr-3 pb-1" align="center" no-gutters justify="space-between" >
    
    <v-col>
    <v-row no-gutters class="text-caption font-weight-regular">{{user.firstName}} {{user.lastName}}</v-row>
    <span class="text-caption ">{{user.email}}</span>
    </v-col>
  </v-row> -->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
//import moment from "moment-timezone";

export default {
  mixins: [HelperMixin],
  props: {
    peopleTagged: {
      type: Array,
      default: null,
    },
    noteTypes: {
      type: Array,
      default: null,
    },
  },
  computed: {},
  methods: {},
}
</script>

<style></style>
